import {
  BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BSidebar,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, max,
} from '@validations'
import vSelect from 'vue-select'
import { showResToast } from '@/@core/utils/utils'
import { config } from '@/api/config-setting'
import { booking } from '@/api/booking'
import { courseSetting } from '@/api/course-setting'
export default {
  name: 'DetailTee',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BSidebar,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
  },
  props: {
    teeId: {
      type: Number,
      required: true,
    },
    KeyValueId: {
      type: Number,
      required: true,
    },
    KeyCode: {
      type: String,
      required: true,
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    isList: {
      type: Boolean,
      required: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      detailTee: {
        Tee: {
          TeeName: null,
          HoleStartID: null,
          TeeLink: [],
        },
      },
      detailKeyValue: null,
      ConfigValue: null,
      dataSelectList: [
      ],
      listBarLocaltion: [],
      courses: [],
      listGolfClass: []
    }
  },
  created() {
    this.getListCourse_RCO01()
    this.getListBarLocaltion()
    // this.getListGolfClass()
    // Dịch vee validate
    if (this.currentLang == 1000000) {
      localize('vi')
    } else {
      localize('en')
    }
    // this.call_api_RCFV02(this.KeyValueId)
  },
  watch: {
    KeyValueId(value) {
      if(value) {
        this.call_api_RCFV02(value)
      }
    },
    KeyCode(value){
      if(value === "TEST_LIST" || value === "BAR_LOCATION_4_MAIN_LOBBY" || value === "BAR_LOCATION_4_MAIN_DRVING_RANGE" || value === "BAR_LOCATION_4_STATER" || value === "BAR_LOCATION_4_PACKAGE_CONFIG" || value === "BAR_LOCATION_4_RATE_CONFIG" ) {
        this.dataSelectList =  this.listBarLocaltion
      }
      if(value === "BOOKING_GOLF_CLASSID_DEFAULT") {
        this.dataSelectList = this.listGolfClass
      }
    }
  },
  methods: {
    async getListBarLocaltion() {
      await config.API_RBAAR().then(res => {
        res.Data.Bar.forEach(x => {
          this.listBarLocaltion.push({Id:x.Id,Name:x.Name})
        })
      })
    },
    async getListCourse_RCO01() {
      await courseSetting.getListCourse().then(res => {
        res.Data.Course.forEach(x => {
          this.courses.push(x.Id)
        })
      })
      const body = {
        CourseId: this.courses
      }
      await booking.getGolfClasses(body).then(res => {
        res.Data.GolfClass.forEach(x => {
          this.listGolfClass.push({Id: x.Id,Name: x.GolfClassName})
        })
      })
    },
    afterUpdateDetailTee(res) {
      this.$emit('afterUpdateDetailTee', res)
    },
    afterDeleteTee(res) {
      this.$emit('afterDeleteTee', res)
    },
    afterUpdateStatusTee(res) {
      this.$emit('afterUpdateStatusTee', res)
    },
    // Thông tin chi tiết Tee
    async call_api_RCFV02(KeyValueId) {
      this.isOpen = true
      const body = {
        ConfigValue: {
          Id: KeyValueId,
        },
      }
      await config
        .API_RCFV02(body)
        .then(res => {
          if (res.Status === '200') {
            const detailKeyValue = res.Data.ConfigValue
            this.ConfigValue = {
              Id: detailKeyValue.Id,
              KeyCode: detailKeyValue.KeyCode,
              KeyValue:  detailKeyValue.KeyValue,
              LangId: detailKeyValue.LangId,
              Zipped: detailKeyValue.Zipped,
            }
          }
        })
    },
    async call_api_UCFV01() {
      this.ConfigValue.LangId = +this.ConfigValue.LangId
      const body = {
        ConfigValue: {
          Id: this.ConfigValue.Id,
          KeyValue: String(this.ConfigValue.KeyValue),
          LangId: this.ConfigValue.LangId,
        },
        Zipped: this.ConfigValue.Zipped ? true : false
      }
      await config
        .API_UCFV01(body)
        .then(res => {
          this.showResToast(res)
          this.ConfigValue = {
            KeyCode: this.KeyCode,
            KeyValue: null,
            LangId: null,
          }
          this.afterAddKeyValue(res)
        })
    },
    OpenAddKeyValue() {
      this.reset()
      this.isOpen = true
      this.isDetail = false
    },
    async call_api_ICFV01() {
      this.ConfigValue.LangId = +this.ConfigValue.LangId
      this.ConfigValue.KeyValue = String(this.ConfigValue.KeyValue)
      const body = {
        ConfigValue: this.ConfigValue,
        Zipped: this.ConfigValue.Zipped ? true : false
      }
      await config
        .API_ICFV01(body)
        .then(res => {
          this.showResToast(res)
          this.afterAddKeyValue(res)
          this.reset()
        })
    },
    afterAddKeyValue(res) {
      this.$emit('afterAddKeyValue', res)
    },
    afterUpdateKeyValue(res) {
      this.$emit('afterUpdateKeyValue', res)
    },
    reset() {
      this.isDetail = false
      this.ConfigValue = {
        KeyCode: this.KeyCode,
        KeyValue: null,
        LangId: null,
        Zipped: false
      }
    },
    showResToast,
  },
}
