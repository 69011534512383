/* eslint-disable no-unreachable */
import BCardCode from '@core/components/b-card-code'
import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BSidebar, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { config } from '@/api/config-setting'
import DeleteDialog from '@core/components/delete-dialog/DeleteDialog.vue'
import DetailKeyValue from '@/views/modules/config/DetailKeyValue/DetailKeyValue.vue'
import AddKeyCode from '@/views/modules/config/AddKeyCode/AddKeyCode.vue'
import { showResToast, showToast } from '@/@core/utils/utils'
export default {
  name: 'ConfigDetail',
  props: [
    'datalanguage',
    'dataPolicy',
    'tabSelect',
    'dataBusiness',
    'dataReport',
    'dataOther'
  ],
  components: {
    DetailKeyValue,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BSidebar,
    BCardCode,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
    'delete-dialog': DeleteDialog,
    AddKeyCode
  },
  data() {
    return {
      listAllConfigGroup: [],
      listConfigGroup: [],
      listHotel: [],
      listOptionTee: [],
      listTee: [],
      KeyGroupSelected: null,
      KeyValueSelected: null,
      listHole: [],
      teeOptions: [],
      RemoveData: null,
      currentLang: this.$i18n.locale,
      isLoadingCourse: false,
      listCount: [],
      totalConfigGroup: [],
      listKeyCode: [],
      listConfigValue: [],
      KeyCodeSelected: null,
      KeyValueSelected: null,
      keyword: null,
      isOpen: false,
      allKeyCode: null,
      KeyCodeFromList: null,
      IsList: false
    }
  },
  created() {
    this.getKeyCodeFromList()
    this.getListConfigGroup()
  },
  methods: {
    async call_api_RCFV01(item) {
      this.IsList = false
      if(this.KeyCodeFromList.filter(x => x.KeyValue === item ).length > 0) {
        this.IsList = true
      }
      this.KeyCodeSelected = item
      const body = {
        KeyCode: item,
      }
      await config.API_RCFV01(body)
        .then(res => {
          this.listConfigValue = res.Data.ConfigValue
        })
    },
    async getListConfigGroup() {
      if(this.datalanguage){
        this.totalConfigGroup = this.datalanguage
      }
      if(this.dataPolicy){
        this.totalConfigGroup = this.dataPolicy
      }
      if(this.dataBusiness){
        this.totalConfigGroup = this.dataBusiness
      }
      if(this.dataReport){
        this.totalConfigGroup = this.dataReport
      }
      if(this.dataOther){
        this.totalConfigGroup = this.dataOther
      }
    },
    async call_api_RCF02(item, isUpdateTee) {
      this.listConfigValue = []
      this.KeyGroupSelected = item

      const body = {
        KeyGroup: item,
      }
      await config
        .API_RCF02(body)
        .then(res => {
          if (res) {
            this.listKeyCode = res.Data.Config
            this.allKeyCode = res.Data.Config
          }
        })
    },
    afterAddKeyValue() {
      this.call_api_RCFV01(this.KeyCodeSelect)
    },
    OpenAddKeyCode(KeyCode) {
      this.$refs.AddKeyCode.open(this.KeyGroupSelected,KeyCode)
    },
    AfterAddKeyCode() {
      this.call_api_RCF02(this.KeyGroupSelected)
    },
    searchKeyCode() {
      const tmpAllKeyCode = []
      this.allKeyCode.forEach(x => {
        if(x.KeyCode.toUpperCase().includes(this.keyword.toUpperCase())){
          tmpAllKeyCode.push(x)
        }
      })
      this.listKeyCode = tmpAllKeyCode
    },
    async getKeyCodeFromList() {
      const body = {
        KeyCode: "CONFIG_KEYCODE_FROM_LIST",
      }
      await config.API_RCFV01(body)
        .then(res => {
         this.KeyCodeFromList = res.Data.ConfigValue
        })
    },
    async DeteleKeyCode(data) {
      const body = {
        Config: {
          KeyCode: data
        }
      }
      await config.API_DCF02(body).then(res => {
        this.showResToast(res)
        if(res.Status === "200") {
          this.call_api_RCF02(this.KeyGroupSelected)
        }
      })
    },
    async DeteleKeyValue(KeyValueId) {
      const body = {
        ConfigValue: {
          Id: KeyValueId
        }
      }
      await config.API_DCFV01(body).then(res => {
        this.showResToast(res)
        if(res.Status === "200") {
          this.afterAddKeyValue()
        }
      })
    }
  },
}
