/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
    ROOT,
    api_RCF01,
    api_RCF02,
    api_RCFV01,
    api_RCFV02,
    api_ICF01,
    api_UCF01,
    api_ICFV01,
    api_UCFV01,
    api_DCFV01,
    api_DCF01,
    api_DCF02,
    api_RRCF01,
    api_RRCF02,
    api_RRCFV01,
    api_RRCFV02,
    api_IRCF01,
    api_URCF01,
    api_IRCFV01,
    api_URCFV01,
    api_DRCFV01,
    api_DRCF01,
    api_DRCF02,
    api_RBAAR,
    api_IRCF02,
    api_RRCF03

} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'


function API_RRCF03(data) {
  return fetch(ROOT + api_RRCF03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function API_IRCF02(data) {
  return fetch(ROOT + api_IRCF02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function API_RBAAR(data) {
  return fetch(ROOT + api_RBAAR, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function API_RCF01(data) {
    return fetch(ROOT + api_RCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

function API_RCF02(data) {
    return fetch(ROOT + api_RCF02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

  function API_RCFV01(data) {
    return fetch(ROOT + api_RCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

  function API_RCFV02(data) {
    return fetch(ROOT + api_RCFV02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_ICF01(data) {
    return fetch(ROOT + api_ICF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_UCF01(data) {
    return fetch(ROOT + api_UCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_ICFV01(data) {
    return fetch(ROOT + api_ICFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_UCFV01(data) {
    return fetch(ROOT + api_UCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_DCFV01(data) {
    return fetch(ROOT + api_DCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_DCF01(data) {
    return fetch(ROOT + api_DCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_DCF02(data) {
    return fetch(ROOT + api_DCF02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

  // CONFIG REPORT
  function API_RRCF01(data) {
    return fetch(ROOT + api_RRCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

function API_RRCF02(data) {
    return fetch(ROOT + api_RRCF02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

  function API_RRCFV01(data) {
    return fetch(ROOT + api_RRCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }

  function API_RRCFV02(data) {
    return fetch(ROOT + api_RRCFV02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_IRCF01(data) {
    return fetch(ROOT + api_IRCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_URCF01(data) {
    return fetch(ROOT + api_URCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_IRCFV01(data) {
    return fetch(ROOT + api_IRCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_URCFV01(data) {
    return fetch(ROOT + api_URCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_DRCFV01(data) {
    return fetch(ROOT + api_DRCFV01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_DRCF01(data) {
    return fetch(ROOT + api_DRCF01, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
  function API_DRCF02(data) {
    return fetch(ROOT + api_DRCF02, requestOptions.postBody(data))
      .then(handleResponse)
      .then(data => data)
  }
export const config = {
    API_RCF01,
    API_RCF02,
    API_RCFV01,
    API_RCFV02,
    API_ICF01,
    API_UCF01,
    API_ICFV01,
    API_UCFV01,
    API_DCFV01,
    API_DCF01,
    API_DCF02,
    API_RRCF01,
    API_RRCF02,
    API_RRCFV01,
    API_RRCFV02,
    API_IRCF01,
    API_URCF01,
    API_IRCFV01,
    API_URCFV01,
    API_DRCFV01,
    API_DRCF01,
    API_DRCF02,
    API_RBAAR,
    API_RRCF03,
    API_IRCF02
}