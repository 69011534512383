import {
    BForm, BFormInput, BRow, BCol,
    BFormGroup, BButton, BSidebar,
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
  import { showResToast } from '@/@core/utils/utils'
  import { config } from '@/api/config-setting'
export default {
    data() {
        return {
            isSave: null,
            isOpen: false,
            keyGroupSelected: null,
            Config: null
        }
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        BForm,
        BSidebar,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BButton,
      },
    methods: {
        open(keyGroupSelected, KeyCodeSelected) {
            this.isSave = KeyCodeSelected
            this.isOpen = true
            this.keyGroupSelected = keyGroupSelected
            this.Config = {
              Keygroup: keyGroupSelected,
              KeyCode: KeyCodeSelected,
            }
          },
        async call_api_ICF01() {
            const body = {
                Config: this.Config
            }
            await config.API_ICF01(body).then(response => {
                this.showResToast(response)
                if(response.Status == 200) {
                    this.isOpen = false
                    this.AfterAddKeyCode()
                }
            })
        },
        async call_api_UCF01() {
          console.log(this.Config)
          const body = {
            Config: this.Config
          }
          await config.API_UCF01(body).then(response => {
            this.showResToast(response)
            if(response.Status == 200){
              this.isOpen = false
              this.AfterAddKeyCode()
            }
          })
        },
        AfterAddKeyCode() {
            this.$emit('AfterAddKeyCode')
          },
        showResToast
    },
}