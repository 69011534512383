import DetailConfig from '@/views/modules/config/DetailConfig/DetailConfig.vue'
import { config } from '@/api/config-setting'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
    name: 'Config',
    components: {
        DetailConfig
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            data: null,
            tabSelect: 0,
            listAllConfigGroup:[],
            listConfigGroup:[],
            totalConfigGroup:[],
            datalanguage: [],
            dataPolicy: [],
            dataBusiness: [],
            dataReport: [],
            dataOther: [],
            check: false,
            checkSite: null
        }
    },
    created() {
        this.call_api_RCF01()
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
          },
    },
    watch: {
        tabSelect() {
            console.log(this.tabSelect)
        }
    },
    methods: {
        async call_api_RCF01() {
            // Lấy danh sách config
            await config
              .API_RCF01()
              .then(res => {
                this.listAllConfigGroup = res.Data.Config
                this.listAllConfigGroup.forEach((value,i) => {
                  if (!this.listConfigGroup.includes(value.KeyGroup)) {
                    this.listConfigGroup.push(value.KeyGroup)
                    const sum = this.listAllConfigGroup.filter((b) => {
                      return b.KeyGroup == value.KeyGroup
                    }).length
                    this.totalConfigGroup.push({ KeyGroup: value.KeyGroup, Count: sum })
                  }
                })
                this.totalConfigGroup.forEach((item,index) => {
                    if(item.KeyGroup == "FRONT_END"){
                        this.datalanguage.push(item)
                    }
                    if(item.KeyGroup == "PAYMENT_POLICY"){
                        this.dataPolicy.push(item)
                    }
                    if(item.KeyGroup == "BOOKING_CONFIG" | item.KeyGroup == "RATE_CONFIG" | item.KeyGroup == "PACKAGE_CONFIG"){
                        this.dataBusiness.push(item)
                    }
                    if(item.KeyGroup == "EMAIL_CONFIRMATION" | item.KeyGroup == "REPORT_CONFIG" | item.KeyGroup == "REPORT_GROUP_CONFIG" | item.KeyGroup == "REPORT_RB" | item.KeyGroup == "REPORT_RCS" | item.KeyGroup == "REPORT_RF" | item.KeyGroup == "EMAIL_ACC_CONFIRMATION"){
                        this.dataReport.push(item)
                    }
                    if(item.KeyGroup !== "FRONT_END" && item.KeyGroup !== "PAYMENT_POLICY" && item.KeyGroup !== "BOOKING_CONFIG" && item.KeyGroup !== "RATE_CONFIG" && item.KeyGroup !== "PACKAGE_CONFIG" && item.KeyGroup !== "EMAIL_CONFIRMATION" && item.KeyGroup !== "EMAIL_CONFIRMATION" && item.KeyGroup !== "REPORT_CONFIG" && item.KeyGroup !== "REPORT_GROUP_CONFIG" && item.KeyGroup !== "REPORT_RB" && item.KeyGroup !== "REPORT_RCS" && item.KeyGroup !== "REPORT_RF" && item.KeyGroup !== "EMAIL_ACC_CONFIRMATION"){
                        this.dataOther.push(item)
                    }
                })
              })
          },
          checkLogSite() {
            const data = localStorage.getItem('currentSiteId')
            if(this.checkSite == data) {
                this.check = true
            } else {
                this.showToast('error', 'Mật khẩu không đúng vui lòng thử lại')
            }
          }
    },
}